import types from "@store/types";

// ** Initial State
const initialState = {
  models: [],
  models_options: [],
  is_loading: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, is_loading: action.value };

    default:
      return state;
  }
};

export default appReducer;
