// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import app from "./app";
import dashboard from "./dashboard";
import UserReducer from "./User";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  app,
  dashboard,
  UserReducer,
});

export default rootReducer;
