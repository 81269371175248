import types from "../../types";

// ** Initial State
const initialState = {
  userData: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_DATA:
      return { ...state, userData: action.value };

    default:
      return state;
  }
};

export default UserReducer;
