const types = {
  GET_TICKETS: "GET_TICKETS",
  GET_YTD_DETAILS: "GET_YTD_DETAILS",
  GET_REVALIDATION_CATEGORY: "GET_REVALIDATION_CATEGORY",
  GET_CHART_DATA: "GET_CHART_DATA",
  GET_TIMELINE_DATA: "GET_TIMELINE_DATA",
  GET_USER_DATA: "GET_USER_DATA",
};

export default types;
