import types from "@store/types";
import _ from "lodash";

// ** Initial State
const initialState = {
  ticket_list: null,
  revalidation_list: [],

  chart_data: [],
  ytd_details: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TICKETS:
      return { ...state, ticket_list: action.value };

    case types.GET_REVALIDATION_CATEGORY:
      return { ...state, revalidation_list: action.value };

    case types.GET_CHART_DATA:
      return { ...state, chart_data: action.value };

    case types.GET_YTD_DETAILS:
      return { ...state, ytd_details: action.value };

    case types.GET_TIMELINE_DATA:
      return { ...state, timelineData: action.value };

    default:
      return state;
  }
};

export default dashboardReducer;
